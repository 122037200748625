<template>
    <div class="menu-wrap">
        <el-menu :defaultActive="navIndex" class="el-menu-vertical-demo" active-text-color="#fd5621" :router="true">
            <el-menu-item class="pc-btn" index="-1" :route="{path:'/add'}">
                <el-button slot="title" type="primary" style="width:100%">发布</el-button>
            </el-menu-item>
            <template v-for="m in navList">
                <el-menu-item v-if="!m.child" :key="m.id" :index="m.id" :route="{path:m.path}">
                    <i :class="m.icon"></i>
                    <span slot="title">{{m.name}}</span>
                </el-menu-item>
                <el-submenu v-else :key="m.id" :index="m.id">
                    <template slot="title">
                        <i :class="m.icon"></i>
                        <span slot="title">{{m.name}}</span>
                    </template>
                    <el-menu-item v-for="(c, ci) in m.child" :route="{path:c.path}" :index="c.id">{{c.name}}</el-menu-item>
                </el-submenu>
            </template>
            <el-menu-item class="phone-btn" index="-1" :route="{path:'/add'}">
                <el-button slot="title" type="primary" style="width:100%">发布</el-button>
            </el-menu-item>
        </el-menu>
        <div class="content">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    import {
        mapGetters,
        mapMutations
    } from "vuex";
    export default {
        data() {
            return {
                navIndex: "1",
                navList: [{
                    id: "1",
                    name: "首页",
                    path: "/my",
                    icon: "el-icon-house"
                }, {
                    id: "2",
                    name: "管理",
                    icon: "el-icon-folder-opened",
                    child: [{
                        id: '2-1',
                        name: '内容管理',
                        path: '/admin_des'
                    }, {
                        id: '2-5',
                        name: '合集管理',
                        path: '/admin_mix'
                    }, {
                        id: '2-2',
                        name: '评论管理',
                        path: '/admin_ping'
                    }, {
                        id: '2-3',
                        name: '经验管理',
                        path: '/admin_rz'
                    }, {
                        id: '2-4',
                        name: '粉丝管理',
                        path: '/admin_fans'
                    }]
                }, {
                    id: "3",
                    name: "创作百科",
                    path: "/ques",
                    icon: "el-icon-magic-stick",
                }],
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            this.checkPath(this.$route);
            if (!this.s_getUser) {
                this._home();
            }
            this.setMeta("我的", 1);
        },
        mounted() {
            // 在组件被挂载之后调用。
        },
        methods: {
            // ...mapMutations([])
            checkPath(t) {
                let p = t.path;
                let info = {
                    '/my': '1',
                    '/admin_des': '2-1',
                    '/admin_ping': '2-2',
                    '/admin_rz': '2-3',
                    '/admin_fans': '2-4',
                    '/admin_mix': '2-5',
                    '/mix_add': '2-5',
                    '/ques': '3',
                }
                if (info[p]) {
                    this.navIndex = info[p]
                }
            },
        },
        computed: {
            // 计算属性
            ...mapGetters(["s_getUser"]),
        },
        watch: {
            // 监听
            $route(to, from) {
                // 路径判断
                this.checkPath(to);
            },
            s_getUser(n) {
                if (!n) {
                    this._home();
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    .menu-wrap {
        display: flex;

        .iconfont {
            font-size: 20px;
            margin-right: 10px;
        }

        .content {
            flex: 1;
            width: 0;
            min-height: 100vh;
            background: #ffff;
            padding: 10px;
        }
    }

    .el-menu-vertical-demo {
        width: 200px;
    }

    /deep/.el-menu-item:focus,
    /deep/.el-menu-item:hover {
        // background-color: #ebf7ff;
        background-color: transparent;
    }

    .pc-btn {
        display: block;
    }

    .phone-btn {
        display: none;
    }

    /* 移动端 */
    @media (max-width: 600px) {
        .pc-btn {
            display: none;
        }

        .phone-btn {
            display: inline-block;
        }

        .menu-wrap {
            display: block;

            .content {
                width: 100%;
                box-sizing: border-box;
            }

            .iconfont {
                margin-right: 0;
            }
        }

        .el-menu-vertical-demo {
            display: flex;
            width: 100%;
            overflow-x: auto;
            box-sizing: border-box;

            span {
                display: none;
            }
        }
        .el-submenu .el-menu-item{
            padding: 0 10px !important;
            min-width: 80px;
        }
    }
</style>